import { Component, SecurityContext } from '@angular/core';
import { Functions, httpsCallable, httpsCallableFromURL } from '@angular/fire/functions';
import { Store } from '@ngxs/store';

import { AuthState, AuthService } from '@dis/auth';
import { PartsState } from '@dis/shared';
import { Build } from '@dis/models'
import { GoogleChatApiService } from '@dis/gapi';
import { DomSanitizer } from '@angular/platform-browser';
import { FcmService } from '../../core/services/fcm.service';


export interface MoveBuildsForm {
  newJobNumber: string;
  builds: Build[];
}


@Component({
  selector: 'app-testing-only',
  templateUrl: './testing-only.component.html',
  styleUrls: ['./testing-only.component.css'],
})
export class TestingOnlyComponent {

  private qboTokens;
  private qboTest;
  private refreshqbotoken;
  private refreshgapitoken;
  private refreshgapitokenurl;

  /** Callable testing functions */
  private callabletestv1
  private callabletestv1Url
  private callabletestv2
  private callabletestv2Url

  // Try that again
  private callableSendFcmNotifications


  data: unknown;

  part$;

  constructor(
    public auth: AuthService,
    private chatService: GoogleChatApiService,
    private fcmService: FcmService,
    private functions: Functions,
    private sanitizer: DomSanitizer,
    private store: Store,
  ) {


    this.part$ = this.store.select(PartsState.getById('XO-1012'))

    this.callableSendFcmNotifications = httpsCallable(this.functions, 'sendFcmNotifications');

    this.qboTokens = this.store.selectSnapshot(AuthState.qboTokens)
    this.qboTest = httpsCallable(this.functions, 'qbotest');
    this.refreshqbotoken = httpsCallable(this.functions, 'refreshqbotoken');
    this.refreshgapitoken = httpsCallable(this.functions, 'refreshgapitoken');
    this.refreshgapitokenurl = httpsCallableFromURL(this.functions, 'https://refreshgapitoken-ysruwvup3q-uc.a.run.app')

    this.callabletestv1 = httpsCallable(this.functions, 'callabletestv1');
    this.callabletestv1Url = httpsCallableFromURL(this.functions, 'https://us-central1-rental-tool-manager.cloudfunctions.net/callabletestv1'),
      this.callabletestv2 = httpsCallable(this.functions, 'callabletestv2');
    this.callabletestv2Url = httpsCallableFromURL(this.functions, 'https://callabletestv2-ysruwvup3q-uc.a.run.app');
  }

  sendFcmMessage() {
    this.fcmService.sendMessage();
  }

  getSourceUrl() {
    const url = "https://app.nutshell.com/timeline";
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  async testchat() {
    console.log(await this.chatService.Messages.get('spaces/AAAAzneVGdU/messages/R-29jg2xTzA.R-29jg2xTzA'))
  }

  runCallableSendFcmNotifications() {
    this.callableSendFcmNotifications({
      name: 'Lenny',
      text: 'fcm message test',
    })
      .then(result => console.log({ result }))
  }



  runCallableTestV1() {
    // return new Promise((res, rej) => {
    this.callabletestv1()
      .then(result => {
        console.log('callabletestv1', result)
        this.data = result
        // res(result)
      })
      .catch((error) => {
        console.error(error)
        // rej(error)
      })
    // })
  }

  runCallableTestV1Url() {
    // return new Promise((res, rej) => {
    this.callabletestv1Url()
      .then(result => {
        console.log('callabletestv1Url', result)
        this.data = result
        // res(result)
      })
      .catch((error) => {
        console.error(error)
        // rej(error)
      })
    // })
  }

  runCallableTestV2() {
    // return new Promise((res, rej) => {
    this.callabletestv2()
      .then(result => {
        console.log('callabletestv2', result)
        this.data = result
        // res(result)
      })
      .catch((error) => {
        console.error(error)
        // rej(error)
      })
    // })
  }

  runCallableTestV2Url() {
    // return new Promise((res, rej) => {
    this.callabletestv2Url()
      .then(result => {
        console.log('callabletestv2Url', result)
        this.data = result
        // res(result)
      })
      .catch((error) => {
        console.error(error)
        // rej(error)
      })
    // })
  }

  refreshQboToken() {
    return new Promise<void>((res, rej) => {
      this.refreshqbotoken({
        ...this.qboTokens
      }).then(result => {
        console.log('qboRefreshTokenCallableFunction', { result });
        this.data = result.data;
        res()
      })
        .catch((error) => {
          console.error(error);
          rej()
        });
    })

  }

  runCallableUrl() {
    return new Promise<void>((res, rej) => {
      const claims = this.store.selectSnapshot(AuthState.claims);
      this.refreshgapitokenurl({ uid: claims?.user_id })
        .then((result: { data: any; }) => {
          console.log('gapiRefreshTokenCallableFunction.url.promise', { result });
          this.data = result.data;
          res()
        })
        .catch((error) => {
          console.error(JSON.parse(JSON.stringify(error)))
        });
    })
  }

  runCallable() {
    return new Promise<void>((res, rej) => {
      const claims = this.store.selectSnapshot(AuthState.claims);
      this.refreshgapitoken({ uid: claims?.user_id })
        .then((result: { data: any; }) => {
          console.log('gapiRefreshTokenCallableFunction', { result });
          this.data = result.data;
          res()
        })
        .catch((error) => {
          console.error(JSON.parse(JSON.stringify(error)))
        });
    })
  }

}
