import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestingOnlyComponent } from './testing-only/testing-only.component';
import { TestingOnlyContainerComponent } from './testing-only-container/testing-only-container.component';
import { SharedModule } from '../shared/shared.module';
import { CustomerSelectControl } from '@dis/shared';



@NgModule({
  declarations: [
    TestingOnlyComponent,
    TestingOnlyContainerComponent
  ],
  imports: [
    CommonModule,
    // JobsGanttChart,
    SharedModule,
    CustomerSelectControl
  ]
})
export class TestingModule { }
