<p>testing-only-works</p>
<!-- <chart-jobs-gantt></chart-jobs-gantt> -->
<!-- <part-image [input]="part$ | async" [showConnections]="false"></part-image> -->
<!-- <print-label></print-label> -->
<!-- <button mat-raised-button (click)="testchat()">Chat testing</button>
<div>
  Callable functions -->
<!-- <button mat-raised-button (click)="runCallableSendFcmNotifications()">Send FCM Test</button> -->
<!-- <button mat-raised-button (click)="runCallable()">Run callable</button> -->
<!-- <button mat-raised-button (click)="runCallableUrl()">Run callable url</button>
  <button mat-raised-button (click)="runCallableTestV1()">Run callable test v1</button>
  <button mat-raised-button (click)="runCallableTestV1Url()">Run callable test v1 url</button>
  <button mat-raised-button (click)="runCallableTestV2()">Run callable test v2</button>
  <button mat-raised-button (click)="runCallableTestV2Url()">Run callable test v2 url</button>
  <button mat-raised-button (click)="refreshQboToken()">Refresh QBO Token</button>
  <img [src]="data">
  <span>Data returned</span> -->
<!-- <pre>{{ data | json }}</pre>
</div> -->

<!-- <iframe [src]="getSourceUrl()" width="600" height="400"></iframe> -->

<!-- <iframe src="https://app.nutshell.com/timeline" width="600" height="400"></iframe> -->

<!-- <control-select-customer></control-select-customer> -->

<!-- <h2>Image CDN Test</h2>
<div>
    <mat-toolbar>/cdn/image/width=400/DSFV-550-158-102.png</mat-toolbar>
  <img srcset="/cdn/image/width=400/DSFV-550-158-102.png 400w,
            /cdn/image/width=200/DSFV-550-158-102.png 200w,
            /cdn/image/width=100/DSFV-550-158-102.png 100w"
            sizes="(min-width: 800px) 200px,
          100px,"
          src="/cdn/image/width=400/DSFV-550-158-102.png"
           alt="Tool"
           style="width: 25%; height: auto"
    width="100" height="75" />
    <mat-toolbar>just img tag</mat-toolbar>
    <img src="/cdn/image/width=800/DSFV-550-158-102.png">
<mat-toolbar>/cdn/image/width=400/images%2Ftools%2FDSFV-550-158-102.png</mat-toolbar>
    <img src="/cdn/image/width=800/images%2Ftools%2FDSFV-550-158-102.png">
    <img src="/cdn/image/width=800/images/tools/DSFV-550-158-102.png">
</div> -->

<button mat-raised-button (click)="sendFcmMessage()">FCM testing</button>
