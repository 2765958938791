import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/jtms-front-end/src/environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  environment;

  constructor( ) {
    this.environment = environment;
   }

  ngOnInit() {
  }

}
