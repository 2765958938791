import { Component, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


import { Select } from '@ngxs/store';
import { NavTreeState } from './state/nav-tree.state';
import { AuthState } from '@dis/auth';

/** Route node data with possible child nodes. */
export interface RouteTreeNode {
  name: string;
  acl?: string[];
  project?: string;
  icon?: string;
  route?: string[];
  children?: RouteTreeNode[];
}

/**
 * Flattened tree node that has been created from a RouteNode through the flattener. Flattened
 * nodes include level index and whether they can be expanded or not.
 */
export interface FlatTreeNode extends RouteTreeNode {
  level: number;
  expandable: boolean;
}

@Component({
  selector: 'app-nav-tree',
  templateUrl: './nav-tree.component.html',
  styleUrls: ['./nav-tree.component.css']
})
export class NavTreeComponent implements OnInit {

  @Select(NavTreeState.appRoutes) appRoutes$!: Observable<RouteTreeNode[]>;

  @Select(AuthState.claims) claims$!: Observable<any>;
  // @Select(PreferencesState.tenant) tenant$: Observable<string>;

  treeNodes$: Observable<RouteTreeNode[]>;

  /** The TreeControl controls the expand/collapse state of tree nodes.  */
  treeControl: FlatTreeControl<FlatTreeNode>;

  /** The TreeFlattener is used to generate the flat list of items from hierarchical data. */
  treeFlattener: MatTreeFlattener<RouteTreeNode, FlatTreeNode>;

  /** The MatTreeFlatDataSource connects the control and flattener to provide data. */
  dataSource: MatTreeFlatDataSource<RouteTreeNode, FlatTreeNode>;

  constructor() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren);
    this.treeControl = new FlatTreeControl(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.treeNodes$ = this.appRoutes$.pipe(
      tap(routes => {
        this.dataSource.data = [];
        this.dataSource.data = routes;
      })
    )
  }

  ngOnInit(): void {
  }

  /** Transform the data to something the tree can read. */
  transformer(node: RouteTreeNode, level: number): FlatTreeNode {
    return {
      ...node,
      level,
      expandable: !!node.children
    };
  }

  /** Get the level of the node */
  getLevel(node: FlatTreeNode): number {
    return node.level;
  }

  /** Get whether the node is expanded or not. */
  isExpandable(node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get whether the node has children or not. */
  hasChild(index: number, node: FlatTreeNode): boolean {
    return node.expandable;
  }

  /** Get the children for the node. */
  getChildren(node: RouteTreeNode): RouteTreeNode[] | null | undefined {
    return node.children;
  }

  //@ts-ignore
  transformRouteLink(node: FlatTreeNode, user_id?: string) {
    const { route } = node;
    if (route) {
      return route[0] === 'user'
        ? ['user', user_id]
        : route.length > 1
          ? [route[0], route[1]]
          : [route[0]]
    }
  }
}
