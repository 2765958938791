import { Injectable, Optional } from "@angular/core";
import { Firestore, arrayUnion, doc, setDoc } from "@angular/fire/firestore";
import { Functions, httpsCallable } from "@angular/fire/functions";
import { Messaging, getToken } from "@angular/fire/messaging";
import { MatSnackBar } from "@angular/material/snack-bar";
import { onMessage } from "firebase/messaging";
import { Store } from "@ngxs/store";
import { AuthState } from "@dis/auth";

import { environment } from "projects/jtms-front-end/src/environments/environment";
@Injectable({
  providedIn: 'root'
})
export class FcmService {
  constructor(
    @Optional() messaging: Messaging,
    private firestore: Firestore,
    private functions: Functions,
    private snackBar: MatSnackBar,
    private store: Store,
  ) {
    if (messaging) {
      navigator.serviceWorker.register('firebase-messaging-sw.js', { type: 'module', scope: '__' })
        .then(serviceWorkerRegistration =>
          getToken(messaging, {
            serviceWorkerRegistration,
            vapidKey: environment.vapidKey,
          })
        )
        .then(token => {
          console.log('FCM', { token });
          setDoc(
            doc(this.firestore, `fcm/${this.store.selectSnapshot(AuthState.user)?.uid}`),
            { tokens: arrayUnion(token) }, { merge: true }
          )
          setDoc(
            doc(this.firestore, `fcm/tokens`),
            {
              [`${this.store.selectSnapshot(AuthState.user)?.uid}`]: arrayUnion(token),
            },
            { merge: true }
          )
        })


      onMessage(messaging, message => {
        console.log('FCM.onMessage', { message });
        const { title, body } = message.notification;
        this.snackBar.open(`${title} | ${body}`, 'Close', {
          duration: 20000,
        });
      })
    }
  }

  async sendMessage() {

    const notification = {
      title: "Test FCM Message",
      body: "",
      icon: 'http://jtms.drillingllc.com/assets/icons/icon-72x72.png',
      click_action: 'https://jtms.drillingllc.com/',
    };

    await httpsCallable(this.functions, 'sendFcmNotifications')({notification})
      .then(console.log)
      .catch(console.error)
  }

  request() {
    Notification.requestPermission();
  }
}
