import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { BehaviorSubject, Subscription } from 'rxjs';

import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';

import { SidenavService } from 'projects/jtms-front-end/src/app/core/services/sidenav.service';

import { AuthService, RefreshGapiTokenService } from '@dis/auth';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit, OnDestroy {

  fixedInViewPort = false;

  loading$ = new BehaviorSubject(false);

  // Viewport params
  @ViewChild('sidenav', { static: true }) public sidenav!: MatSidenav;

  routerSubscription!: Subscription;

  constructor(
    public auth: AuthService,
    public refreshTokenService: RefreshGapiTokenService,
    private router: Router,
    public sidenavService: SidenavService,
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading$.next(true);
          if (this.sidenavService.isHandset) {
            this.sidenavService.close().then(() => { });
          }
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading$.next(false);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    // Store Sidenav to service
    this.sidenavService.setSidenav(this.sidenav);
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

}
