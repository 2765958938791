
import { FIREBASE_CONFIG } from '.environments/environment.firebase';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import packageInfo from '../../../../package.json';

export const environment = {
  appVersion: packageInfo.version,
  name: 'production',
  version: "VERSION",
  production: true,
  useEmulators: false,
  clientId: '94059983823-bjsp8rge8hn6hfvdi0le5704eld6ql6k.apps.googleusercontent.com',
  clientSecret: '_fd8jsP_LBhABWswGF08WtgH',
  firebase: FIREBASE_CONFIG,
  vapidKey: "BBhl2mfNy61V1yU0Z4OQSDpVyj6PK8ZbHNbT1sQ82uofW05N-HqEPYa9-JkvDwqog5rdAqhR1E3WoBC595sxv98",
  functions: {
    origin: 'https://jtms.drillingllc.com'
  },
  ngxs: {
    plugins: [
      NgxsReduxDevtoolsPluginModule.forRoot()
    ]
  },
  sentry: {
    dsn: 'https://595633923d6e4b039e7b646b57688579@o4504934877822976.ingest.sentry.io/4504934882213888',
    release: `jtms-front-end@${packageInfo.version}`,
  },
};
