<mat-card>

  <mat-card-header>

    <mat-card-title>
        <span>About JTMS</span>
    </mat-card-title>

    <mat-card-subtitle>
      <span>Job & Tool Management System</span>
    </mat-card-subtitle>

  </mat-card-header>

  <mat-card-content fxLayoutAlign="center">
    <div>
      <p><strong>Mode:</strong> {{ environment.name | uppercase }}</p>
      <p><strong>AppVersion:</strong> {{ environment.appVersion }}</p>
      <p><strong>Build:</strong></p>
    </div>
  </mat-card-content>

  <mat-card-footer class="footer">

    <mat-card-subtitle>
      <div>
        <p>Built by <a href="https://acadianaapps.com" target="_blank">Acadiana Apps</a></p>
        <p><a href="mailto:info+jtms@acadianaapps.com" target="_blank">info+jtms&#64;acadianaapps.com</a></p>
      </div>
    </mat-card-subtitle>

  </mat-card-footer>

</mat-card>
