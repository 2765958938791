<mat-card class="card">
  <mat-card-header>
    <mat-card-title>
    Job and Tool Management System
  </mat-card-title>
  <mat-card-subtitle>
    Drilling Innovative Solutions, LLC
  </mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image src="../../../assets/images/logo/drilling-logo.gif">
  <mat-card-content class="content">
    <!-- <p>content</p> -->
  </mat-card-content>
  <mat-card-actions></mat-card-actions>
</mat-card>
