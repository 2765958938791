import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { SidenavService } from '../../core/services/sidenav.service';

import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AppUser, AuthState } from '@dis/auth';
import { ActivePressureTest, PressureTestCollection } from '../../db/pressure-test.collection';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnDestroy {
  // attach to pipes and destroy all subscribtions
  destroy$: Subject<boolean> = new Subject<boolean>();

  isHandset!: Boolean;

  pressureTest$: Observable<any>;

  // TODO: implement user icon and profile router link
  user$: Observable<any>;

  constructor(
    private pressureTestService: PressureTestCollection,
    public sidenavService: SidenavService,
    private store: Store,
  ) {
    this.sidenavService.isHandset$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(status => this.isHandset = status);

    this.user$ = this.store.select(AuthState.claims);

    this.pressureTest$ = this.pressureTestService.doc$('activeTest');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
