import { Component, OnInit, isDevMode } from '@angular/core';
import { NgswService } from './core/services/ngsw.service';
import { FcmService } from './core/services/fcm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Job & Tool Management System';

  constructor(
    private fcmService: FcmService, // This is needed to start FCM process
    private ngswService: NgswService,
  ) { }

  ngOnInit(): void {
    // Angular Serviceworker initilization;
    // Second release
    if (!isDevMode()) {
      this.ngswService.initSwUpdate();
      this.ngswService.initSwPush();
    }
  }
}
