<mat-sidenav-container class="sidenav-container">

  <mat-sidenav #sidenav class="sidenav" [fixedInViewport]="fixedInViewPort"
    [attr.role]="(sidenavService.isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(sidenavService.isHandset$ | async) ? 'over' : 'side'" [opened]="!(sidenavService.isHandset$ | async)">

    <app-nav-tree></app-nav-tree>

  </mat-sidenav>

  <mat-sidenav-content>

    @if (loading$ | async) {
    <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
    }

    <router-outlet></router-outlet>

  </mat-sidenav-content>

</mat-sidenav-container>