import { Component } from '@angular/core';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent {

  constructor(
    private store: Store
  ) {

  }
  handleLocalStorageClear() {
    localStorage.clear();
  }

  handleStoreReset() {
    this.store.reset({})
  }
}
