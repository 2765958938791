import { APP_INITIALIZER, NgModule } from '@angular/core';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';

// import {
//   initializeAppCheck,
//   provideAppCheck,
//   ReCaptchaV3Provider,
//   AppCheckModule
// } from '@angular/fire/app-check';

import { getAuth, provideAuth } from '@angular/fire/auth';

import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';

// import {
//   connectAuthEmulatorInDevMode,
//   connectDatabaseEmulatorInDevMode,
//   connectDataconnectEmulatorInDevMode,
//   connectFirestoreEmulatorInDevMode,
//   connectFunctionsEmulatorInDevMode,
//   connectStorageEmulatorInDevMode
// } from '.emulators/emulators';

import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';

import { getStorage, provideStorage } from '@angular/fire/storage';

import { environment } from 'projects/jtms-front-end/src/environments/environment';
import { getDataConnect } from 'firebase/data-connect';
import { connectorConfig } from 'dataconnect-generated/js/default-connector-connector';

@NgModule({
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        const dataconnect = getDataConnect(connectorConfig);
        // if (environment.useEmulators) {
        //   connectDataconnectEmulatorInDevMode(dataconnect);
        // }
        return dataconnect;
      },
      multi: true
    },
    // ...[
    //   environment.useEmulators
    //     ? [AppCheckModule]
    //     : [
    //       provideAppCheck(() => {
    //         const provider = new ReCaptchaV3Provider(environment.appCheck.recaptcha3SiteKey);
    //         return initializeAppCheck(getApp(), { provider, isTokenAutoRefreshEnabled: true });
    //       })
    //     ]
    // ],
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        // connectAuthEmulatorInDevMode(auth);
      }
      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      if (environment.useEmulators) {
        // connectFirestoreEmulatorInDevMode(firestore);
      }
      return firestore;
    }),
    provideFunctions(() => {
      const functions = getFunctions();
      if (environment.useEmulators) {
        // connectFunctionsEmulatorInDevMode(functions);
      }
      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators) {
        // connectStorageEmulatorInDevMode(storage);
      }
      return storage;
    }),
    ScreenTrackingService,
    UserTrackingService,
  ],
})
export class FirebaseModule { }
