export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCbboYqG_95wqpf3OdfFYnRV1PaiFtnJPI',
  authDomain: 'auth.drillingllc.com',
  databaseURL: 'https://rental-tool-manager.firebaseio.com',
  projectId: 'rental-tool-manager',
  storageBucket: 'rental-tool-manager.appspot.com',
  messagingSenderId: '94059983823',
  appId: '1:94059983823:web:b53d3a7634df3d90b298ba',
  measurementId: 'G-VCGCZV4LGS'
}
