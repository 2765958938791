import { Injectable } from "@angular/core";
import { DocumentData, FirestoreDataConverter } from "@angular/fire/firestore";
import { Build } from "@dis/models";
import { FirestoreV9Service } from "@dis/shared";

export interface PressureTestCollection {
  activeTest: ActivePressureTest;
}

export interface ActivePressureTest {
  build: Build;
  graphAnnotationText: string;
  graphRecording: string;
  name: string;
  startTestPressure: string;
  startTestTime: string;
  stopTestPressure: string;
  stopTestTime: string;
  testInProgress: boolean;
  testType: string;
}

@Injectable({
  providedIn: 'root'
})
export class PressureTestCollection extends FirestoreV9Service<PressureTestCollection> {
  protected override basePath = 'pressure-test';
  protected override baseConverter: FirestoreDataConverter<PressureTestCollection, DocumentData>;
}
