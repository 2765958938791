import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HomeComponent } from './home/home.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { AboutComponent } from './about/about.component';
import { RouterModule } from '@angular/router';
import { NavTreeComponent } from './nav-tree/nav-tree.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([]),
  ],
  declarations: [
    LoadingSpinnerComponent,
    HeaderComponent,
    SidenavComponent,
    NavTreeComponent,
    HomeComponent,
    AboutComponent,
    PageNotFoundComponent,
  ],
  exports: [
    HeaderComponent,
    SidenavComponent,
    PageNotFoundComponent,
  ]
})
export class UiModule { }
