import { Component, Optional } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Messaging, getToken, onMessage } from "@angular/fire/messaging";

import { Observable, EMPTY, from, tap, share } from "rxjs";

import { environment } from "projects/jtms-front-end/src/environments/environment";
import { Firestore, arrayUnion, doc, setDoc, updateDoc } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngxs/store";
import { AuthState } from "@dis/auth";

@Component({
  standalone: true,
  selector: 'app-messaging',
  template: `
    <h3>FCM Messaging!</h3>
    <div>
      <p>Token</p>
      <code>{{ token$ | async }}</code>
    </div>
    <div>
      <p>Message</p>
      <code>{{ message$ | async | json }}</code>
    </div>
    @if (showRequest) {
      <button (click)="request()">Request FCM token</button>
    }
    `,
  styles: [],
  imports: [
    CommonModule,
  ]
})
export class FcmComponent {
  token$: Observable<any> = EMPTY;
  message$: Observable<any> = EMPTY;
  showRequest = false;

  constructor(
    @Optional() messaging: Messaging,
    private firestore: Firestore,
    private snackBar: MatSnackBar,
    private store: Store,
  ) {
    if (messaging) {
      this.token$ = from(
        navigator.serviceWorker.register('firebase-messaging-sw.js', { type: 'module', scope: '__' }).
          then(serviceWorkerRegistration =>
            getToken(messaging, {
              serviceWorkerRegistration,
              vapidKey: environment.vapidKey,
            })
          )).pipe(
            tap(token => {
              console.log('FCM', { token });
              setDoc(
                doc(this.firestore, `fcm/${this.store.selectSnapshot(AuthState.user)?.uid}`),
                { tokens: arrayUnion(token) }, { merge: true }
              )
            }),
            share()
          );
      this.message$ = new Observable(sub => onMessage(messaging, it => sub.next(it))).pipe(
        tap(it => {
          console.log('FCM', { it });
          const { title, body } = it.notification;
          this.snackBar.open(`${title} | ${body}`, 'Close');
        }),
      );
    }
  }

  ngOnInit(): void {
  }

  request() {
    Notification.requestPermission();
  }
}
